/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { SignalRHubService, HubConnection, SignalRConnectionSettings } from '@notifications/signalr-hub.service';
import { AppLoadService } from '@app/app-load.service';
import { environment } from '@environments/environment';
import { ReconnectionHub } from '../interfaces/reconnection-hub';

@Injectable()
export class WarehouseStatusClient {
    //endPoint: string;
    connection: HubConnection;
    private reconnectionHandlers: ReconnectionHub = {};

    constructor(
        private hubService: SignalRHubService,
        private appLoadService: AppLoadService,
    ) { }

    public connectToHub(onReconnected?: () => Promise<void>, onDisconnected?: () => Promise<void>) {
        try {
            if (onReconnected) {
                this.reconnectionHandlers['WarehouseStatusClient'] = {
                    ['ConnectionLost']: onReconnected,
                };
            }
            return new Promise((resolve) => {
                try {
                    if (environment.mode !== 'front') {
                        this.appLoadService.getCurrentWarehouse.subscribe(async (res) => {
                            try {
                                //this.endPoint = `${res.hostName}/warehouseStatus`.toLowerCase();
                                /* FROM */
                                // const signalRNegotiateActualUrlToken = await this.getSignalRUrlAndAccessToken('WarehouseStatus');
                                // this.endPoint = signalRNegotiateActualUrlToken?.url ?? this.endPoint;
                                // const token = signalRNegotiateActualUrlToken?.accessToken ?? '';
                                /* TO */

                                this.hubService
                                    .connect('WarehouseStatus'/*this.endPoint*/, this.handleReconnection.bind(this), onDisconnected, null, null/*, token*/)
                                    .then((connection) => (this.connection = connection))
                                    .then(resolve);
                            } catch (error) {
                                console.error(error);
                            }
                        });
                    } else {
                        resolve(true);
                    }
                } catch (error) {
                    console.error(error);
                }
            });
        } catch (error) {
            console.error(error);
        }
    }

    // async getSignalRUrlAndAccessToken(hub) {
    //     const signalRUrlAndAccessToken: SignalRConnectionSettings = await this.hubService
    //         .signalRNegotiateWithServer(hub)
    //         .toPromise()
    //         .then((negotiateCredentials) => {
    //             return { accessToken: negotiateCredentials['AccessToken'], url: negotiateCredentials['Url'] };
    //         });
    //     return signalRUrlAndAccessToken;
    // }

    public disconnectFromHub() {
        try {
            if (environment.mode !== 'front') {
                this.hubService.disconnect(this.connection);
                Object.keys(this.reconnectionHandlers || {}).forEach((groupName) => {
                    Object.keys(this.reconnectionHandlers[groupName] || {}).forEach((eventName) => {
                        this.reconnectionHandlers[groupName][eventName] = null;
                        delete this.reconnectionHandlers[groupName][eventName];
                    });
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    public subscribe(eventName: string, groupName: string, notificationHandler: (...args: any[]) => void, onReconnect: (...args: any[]) => Promise<void>) {
        const that = this;
        if (onReconnect) {
            if (!this.reconnectionHandlers[groupName]) {
                this.reconnectionHandlers[groupName] = {};
            }
            this.reconnectionHandlers[groupName][eventName] = onReconnect;
        }
        return new Promise((resolve) => {
            // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
            (function subscribe(innerEventName, innerGroupName, innerHandler) {
                if (that.connection) {
                    resolve(that.hubService.subscribe(that.connection, innerEventName, innerGroupName, innerHandler));
                } else {
                    setTimeout(() => subscribe(innerEventName, innerGroupName, innerHandler), 5000);
                }
            })(eventName, groupName, notificationHandler);
        });
    }

    public async unsubscribe(eventName: string, groupName: string): Promise<void> {
        if (this.reconnectionHandlers?.[groupName]?.[eventName]) {
            this.reconnectionHandlers[groupName][eventName] = null;
            delete this.reconnectionHandlers[groupName][eventName];
        }
        await this.hubService.unsubscribe(this.connection, eventName, groupName);
    }

    public subscribe$(
        groupName: string,
        supportedEvents: string[],
        notifyEvent: (groupName: string, eventName: string, eventGroup: any[]) => void,
        onReconnect: (...args: any[]) => Promise<void>,
    ): Promise<void> {
        const that = this;
        try {
            if (onReconnect) {
                if (!this.reconnectionHandlers[groupName]) {
                    this.reconnectionHandlers[groupName] = {};
                }
                supportedEvents.forEach((eventName) => {
                    this.reconnectionHandlers[groupName][eventName] = onReconnect;
                });
            }
            return new Promise((resolve) => {
                // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                (function subscribe$() {
                    try {
                        if (that.connection) {
                            return resolve(that.hubService.subscribe$(that.connection, groupName, supportedEvents, notifyEvent));
                        } else {
                            return new Promise((res, rej) => setTimeout(res, 250)).then(subscribe$);
                        }
                    } catch (error) {
                        console.error(error);
                    }
                })();
            });
        } catch (error) {
            console.error(error);
        }
    }

    public async unsubscribe$(groupName: string, supportedEvents: string[]) {
        try {
            supportedEvents.forEach((eventName) => {
                if (this.reconnectionHandlers?.[groupName]?.[eventName]) {
                    this.reconnectionHandlers[groupName][eventName] = null;
                    delete this.reconnectionHandlers[groupName][eventName];
                }
            });
            await this.hubService.unsubscribe$(this.connection, groupName, supportedEvents);
        } catch (error) {
            console.error(error);
        }
    }

    private async handleReconnection() {
        try {
            Object.values(this.reconnectionHandlers).forEach(async (group) => {
                await Object.values(group).forEach(async (handler) => {
                    await handler();
                });
            });
        } catch (error) {
            console.error(error);
        }
    }
}
